import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { Carrier } from "src/app/model/carrier";
import * as carrierActions from "../carriers/carrier.actions";

export interface CarrierState {
  carrierList: Carrier[];
}

const initialState: CarrierState = {
  carrierList: [],
};

const _carrierReducer = createReducer(
  initialState,
  on(carrierActions.fetchCarrierListSuccess, (state, { carrierList }) => ({
    ...state,
    carrierList: carrierList.find((carrier) => carrier.id === 53)
      ? [carrierList.find((carrier) => carrier.id === 53)]
      : carrierList,
  }))
);

export function carrierReducer(state: CarrierState, action: Action) {
  return _carrierReducer(state, action);
}

const selectCarrierState = createFeatureSelector<CarrierState>("carrierList");

export const selectCarrierList = createSelector(
  selectCarrierState,
  (state: CarrierState) => state.carrierList
);
